import * as React from "react"

import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import PlansNav from '../../../components/nav/PlansNav'
import MedicareContent from '../../../components/data/MedicareContent'
import EnrollOnline from '../../../components/ui/EnrollOnline';

const UVMHealthAdvantageSelect = ({ location }) => (
	<Layout location={location} heading1="Medicare Advantage Plans" heading2="Find the right plan for your needs and lifestyle.">
    	<Seo title="Medicare Advantage Plans" meta={[{description: 'Choose from Medicare Advantage plans as low as $0 per month.'}]} bodyclass="plans subpage" />
		<PlansNav />

		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">
					<main>
						{ /* <MedicareContent route={'getplandetail/v1/pid/' + process.env.GATSBY_UVM_HEALTH_ADVANTAGE_SELECT + '/VT/Addison/2023'} /> */ }

						<div>
							<h3 class="planname">UVM Health Advantage Select (PPO)</h3>
							<p><strong>IN = In-Network; OUT = Out-of-Network</strong></p>
							<div id="details-wrap" class="plandetailtable-wrap plan-color-greenUVM">
								<table id="plan-color-greenUVMdetails" class="plandetailtable" cellspacing="0">
									<tbody>
										<tr>
											<th scope="row">Star Rating</th>
											<td>3.5 out of 5 stars</td>
										</tr>
										<tr>
											<th scope="row">Monthly Plan Premium</th>
											<td><strong>$0 with Part D</strong></td>
										</tr>
										<tr>
											<th scope="row">Part D Prescription Drug Coverage</th>
											<td>Tiers 1 and 2: <strong>No deductible</strong>.<br />Tiers 3-5: <strong>$250 deductible.</strong> You pay 100% of the cost of retail and mail order drugs until you spend $250.<br /></td>
										</tr>
										<tr>
											<th scope="row">Part D 30-Day Supply <span>- Co-pays differ in the coverage gap. LIS, VPharm, or EPIC may reduce your co-pays.</span></th>
											<td class="nopadding">
												<table class="tierpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>Tier 1 - $0<br />Tier 2 - $10<br />Tier 3 - $47</td>
															<td>Tier 4 - $100<br />Tier 5 - 28% co-insurance</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th scope="row">Primary Doctor Visit</th>
											<td class="nopadding">
												<table class="inoutpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>IN - $0</td>
															<td>OUT - $5</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th scope="row">Specialist Visit</th>
											<td class="nopadding">
												<table class="inoutpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>IN - $35</td>
															<td>OUT - $50</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th scope="row">Urgently Needed Care <span>Worldwide Coverage</span></th>
											<td>$30</td>
										</tr>
										<tr>
											<th scope="row">Emergency Room Care <span>Worldwide Coverage</span></th>
											<td>$95</td>
										</tr>
										<tr>
											<th scope="row">Inpatient Hospital Stays <span>Emergency admissions covered worldwide</span></th>
											<td class="nopadding">
												<table class="inoutpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>IN - $355/day for days 1-3, $0/day for days 4+</td>
															<td>OUT - $500/day for days 1-5, $0/day for days 6+</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th scope="row">Ambulatory Surgical Center</th>
											<td class="nopadding">
												<table class="inoutpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>IN - $175</td>
															<td>OUT - $250</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th scope="row">Outpatient Hospital</th>
											<td class="nopadding">
												<table class="inoutpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>IN - $225</td>
															<td>OUT - $285</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th scope="row">X-ray</th>
											<td class="nopadding">
												<table class="inoutpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>IN - $10</td>
															<td>OUT - $10</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th scope="row">Lab</th>
											<td class="nopadding">
												<table class="inoutpricing" cellspacing="0">
													<tbody>
														<tr>
															<td>IN - $0</td>
															<td>OUT - 40% co-insurance</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<th class="hearingaid" scope="row">Hearing Aid Allowance</th>
											<td>Hearing aids for $699 or $999 per ear OR up to $600 per hearing aid toward your choice of top models. Batteries included!</td>
										</tr>
										<tr>
											<th scope="row">Eyewear Allowance</th>
											<td>$175 per year</td>
										</tr>
										<tr>
											<th scope="row">Preventive Dental Coverage</th>
											<td>Two cleanings, two exams, and two sets of  x-rays per year</td>
										</tr>
										<tr>
											<th scope="row">Comprehensive Dental Coverage</th>
											<td>$1,250 allowance per year, no deductible, 0% co-insurance for non-Medicare covered services, $40 co-pay for Medicare covered services</td>
										</tr>
										<tr>
											<th scope="row">SilverSneakers<sup>®</sup> Fitness Program</th>
											<td>Gym membership and SilverSneakers classes</td>
										</tr>
										<tr>
											<th scope="row">Virtual Care Services through Gia®</th>
											<td>$0</td>
										</tr>
										<tr>
											<th scope="row">Over-the-counter Purchases</th>
											<td>$50 allowance per quarter</td>
										</tr>
										<tr>
											<th scope="row">Meal Delivery</th>
											<td>14 free refrigerated meals after an in-patient hospital stay discharge</td>
										</tr>
										<tr>
											<th scope="row">Transportation</th>
											<td>24 free rides to or from medical appointments (60 miles max per ride)</td>
										</tr>
										<tr>
											<th scope="row">Out-of-Network Coverage for Non-Urgent and Non-Emergency Services</th>
											<td>Up to $50 office visits; 40% co-insurance for most other services</td>
										</tr>
										<tr>
											<th scope="row">Out-of-Pocket Protection That Includes Part B Drugs</th>
											<td>$6,700 max per year for your protection<br />Once met, MVP pays 100% of covered services</td>
										</tr>
									</tbody>
								</table>
							</div>
							<h4>Want more information?</h4>
							<ul class="related-plandocs">
								<li><a rel="noopener noreferrer" class="download" href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/uvmha-sob-2024.pdf">2024 Summary of Benefits for UVM Health Advantage Select with Part D (PPO) (PDF)</a></li>
								<li><a rel="noopener noreferrer" class="download" href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/uvm-health-advantage-anoc-select-2024.pdf">2024 Annual Notice of Change for UVM Health Advantage Select with Part D (PPO) (PDF)</a></li>
								<li><a rel="noopener noreferrer" class="download" href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/uvm-health-advantage-eoc-select-2024.pdf">2024 Evidence of Coverage for UVM Health Advantage Select with Part D (PPO) (PDF)</a></li>
								<li><a rel="noopener noreferrer" class="download" href="https://www.mvphealthcare.com/-/media/project/mvp/healthcare/documents-by-section/plans-documents/medicare-and-uvm-health/uvmha-sob-2023.pdf">2023 Summary of Benefits for UVM Health Advantage Select with Part D (PPO) (PDF)</a></li>
								<li><a rel="noopener noreferrer" class="download" href="https://www.mvphealthcare.com/-/media/project/mvp/healthcare/documents-by-section/plans-documents/medicare-and-uvm-health/uvm-health-advantage-anoc-select-2023.pdf">2023 Annual Notice of Change for UVM Health Advantage Select with Part D (PPO) (PDF)</a></li>
								<li><a rel="noopener noreferrer" class="download" href="https://www.mvphealthcare.com/-/media/project/mvp/healthcare/documents-by-section/plans-documents/evidence-of-coverage/uvm-health-advantage-eoc-select-2023.pdf">2023 Evidence of Coverage for UVM Health Advantage Select with Part D (PPO) (PDF)</a></li>
								<li><a rel="noopener noreferrer" class="download" href="https://www.mvphealthcare.com/-/media/project/mvp/healthcare/documents-by-section/plans-documents/medicare-and-uvm-health/h9615-medicare-star-rating-2023-english.pdf">2023 Medicare Star Ratings (PDF)</a></li>
							</ul>
						</div>

						<div className="ctas secondary">						
							<div className="flexwrap">
								<EnrollOnline />
							</div>
						</div>
						{/*
						<h3>UVM® Health Advantage Select (PPO)</h3>
						<h4>IN = In-Network; OUT = Out-of-Network</h4>

						[table]
						
						<h4>Want more information?</h4>
						<ul class="plan-documents">
							<li><a href="##" class="download">2021 Summary of Benefits for UVM® Health Advantage Select (PPO) (PDF)</a></li>
							<li><a href="##" class="download">2021 Annual Notice of Change for UVM® Health Advantage Select (PPO) (PDF)</a></li>
							<li><a href="##" class="download">2021 Evidence of Coverage for UVM® Health Advantage Select (PPO) (PDF)</a></li>
							<li><a href="##" class="download">2021 Medicare Star Ratings (PDF)</a></li>
							<li><a href="##" class="download">View a side-by-side plan comparison (PDF)</a></li>
						</ul>

						<h3 class="small">Ready to enroll?</h3>
						<p><a href="##" class="external">Enroll online</a></p>
						<p>Or download your application: <a href="##" class="download">2022 Application (PDF)</a></p>
						*/}
					</main>
				</div>
			</div>
		</div>
	</Layout>
)

export default UVMHealthAdvantageSelect
